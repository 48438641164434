<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/loadSections";
import firebaseDeliveryBindOrder from "@/mixins/firebase/bindDeliveryOrder";

export default {
  name: "poinPlusCanceled",

  metaInfo: { title: "Mi Pedido" },

  extends: View,

  mixins: [
    firebaseDeliveryBindOrder,
    LoadSections(["public-MpPointPlusCanceled"]),
  ],
};
</script>